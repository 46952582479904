import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
`;

export const Logo = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
